import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['search', 'results', 'reset'];

  connect() {
    const search = this.searchTarget, results = this.resultsTarget, reset = this.resetTarget;

    search.addEventListener('keyup', function(evt){
      Rails.fire(search.form, 'submit');
    });

    document.querySelectorAll('#filter-search [name="filter"]').forEach((filter) => {
      filter.addEventListener('change', function(evt){
        filter.form.submit();
      })
    });

    reset.addEventListener('click', function(evt){
      evt.preventDefault();
      search.value = '';
      if (results.className == 'quicksearch_results') {
        results.innerHTML = ''
      } else {
        Rails.fire(search.form, 'submit');
      }
    });
  }
}
