import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    document.getElementById('bulk_delete_button').classList.add('disabled');
  }

  mark_for_delete(evt) {
    var button = evt.target.closest('button');
    var comment = button.closest('div.comment-admin');
    var comment_id = comment['id'].split('_').pop();
    var ids = document.getElementById('bulk_comment_' + comment_id);
    ids.value = ids.value ? null : comment_id;
    button.classList.toggle('text-danger');
    comment.classList.toggle('queued-for-delete');
    var submit = document.getElementById('bulk_delete_button');
    var selected = document.querySelectorAll('.close.text-danger');
    if (selected.length > 0) {
      submit.classList.remove('disabled');
    } else {
      submit.classList.add('disabled');
    }
  }
}

