import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["main", "remote", "replacement", "submit"];

  connect() {
    console.log('ready to zap some typos!')
    this.submitTarget.disabled = true;
  }

  sync(evt) {
    const main = evt.target;
    this.remoteTargets.forEach((elm) => {
      elm.checked = main.checked;
    });
    this.allow();
  }

  indie(evt) {
    const main = evt.target;

    this.mainTarget.indeterminate = this.remoteTargets.length != this.selects;
    this.mainTarget.checked = this.remoteTargets.length == this.selects;
    this.allow();
  }

  working(evt) {
    document.body.insertAdjacentHTML('beforeend', '<div class="currently-waiting d-flex justify-content-center align-items-center"><div class="spinner-border text-light" role="status"><span class="sr-only">Loading...</span></div></div>')
  }

  allow() {
    this.submitTarget.disabled = !this.replacementTarget.value || (this.selects == 0);
  }

  get selects() {
    var selected = Array.from(this.remoteTargets).filter((elm) => { return elm.checked }).length;
    return selected;
  }
}
