import { Controller } from "stimulus"
import { Sortable } from "sortablejs"

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      // group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this),
      handle: '.handle'
    })
  }

  end(event) {
    $(event.from).find('.position').each(function(idx, elm){
      $(elm).val(idx + 1);
    });
  }
}
