import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = ["quote_container"]

  connect(){
    let template = document.getElementById('quote_form').innerHTML;

    this.element.addEventListener('mouseup', function(evt){
      const elm = evt.target;
      const form = elm.closest('.quote-tool');
      if(form){
        return true;
      }
      const selected = document.getSelection().toString() || false;
      if(!selected) return true

      const fulltext = document.getSelection().anchorNode.parentElement.innerText || '';
      let start = document.getSelection().anchorNode.parentElement;
      if(!start.id){
        start = start.closest('*[id]') || document.body.children.item(0);
      }
      for(var i = 0; i < document.getElementsByClassName('quote-tool').length; i++){
        document.getElementsByClassName('quote-tool').item(i).remove();
      };
      if(selected){
        template = template.replace('_anchor', start.id).replace('_quotation', selected).replace('_fulltext', fulltext)
        start.insertAdjacentHTML('beforebegin', template);
      }
    });
  }
}
