import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const button = this.element;
    const input = button.closest('.input-group').querySelector('input') || null;
    const living = button.closest('.input-group').querySelector('.living') || null;
    const unknown = button.closest('.input-group').querySelector('.unknown') || null;
    const changed = new CustomEvent('value:changed');

    const show_state = function() {
      if (input.value.length > 0 && !input.value.match(/^-?[0-9]{1,4}$/)) {
        input.classList.add('is-invalid')
      }
      if (input.value == '8888') {
        unknown.classList.add('active', 'font-weight-bolder');
        if (!!living) { living.classList.remove('active', 'font-weight-bolder') };
      }

      if (input.value == '9999' && !!living) {
        living.classList.add('active', 'font-weight-bolder')
        if (!!unknown) { unknown.classList.remove('active', 'font-weight-bolder') };
      }
    }

    if(!input) {
      console.log('Could not connect properly to the related input');
      return;
    } else {
      show_state();
      input.addEventListener('value:changed', show_state);
      input.addEventListener('input', show_state);
    }

    button.addEventListener('click', function(evt) {
      evt.preventDefault();

      if (button.classList.contains('unknown')) {
        input.focus();
        input.value = '8888';
        input.dispatchEvent(changed);
      }

      if (button.classList.contains('living')) {
        input.focus();
        input.value = '9999';
        input.dispatchEvent(changed);
      }
    });
  }

}
