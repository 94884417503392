import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['image', 'form'];

  connect() {
    const img = this.imageTarget;
    const form = this.formTarget;

    var mark = function(){
      var id = img.id.split('_').pop();
      if(!!id && id.length > 0) {
        Rails.fire(form, 'submit');
      }
    }

    img.addEventListener('click', mark, true);
  }

}

