import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["picker"];

  connect() {
    const elm = this.pickerTarget, add = 'Add...';
    const text_field = '<input type="text" id="' + elm.id + '" name="' + elm.name + '" class="' + elm.className + '">';

    elm.options[elm.options.length] = new Option(add, add);
    elm.addEventListener('change', function(evt){
      let val = this.options[this.selectedIndex].value;
      if (val == add){
        let restore = elm.cloneNode(true);
        elm.parentNode.insertAdjacentHTML('beforeend', text_field);
        let replacement = elm.nextSibling;
        elm.remove();
        replacement.focus();
        replacement.addEventListener('blur', function(evt){
          if(this.value == ''){
            replacement.replaceWith(restore);
          }
        });
      }
    });
  }
}