import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['on', 'off', 'switcher'];

  connect() {
    const on = this.onTarget, off = this.offTarget, switcher = this.switcherTarget;

    var toggle = function(){
      if(!!switcher.checked){
        $(on).hide();
        $(off).show();
      }else{
        $(off).hide();
        $(on).show();
      }
    }

    toggle();

    switcher.addEventListener('click', toggle, true);
  }

}

