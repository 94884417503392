import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('upload-o-rama')
  }

  check() {
    this.reset();
    const regexp = new RegExp('^[-a-z_A-Z0-9\.]+\.[a-zA-Z]{3,4}$');
    var name = this.element.value.toString().split('\\').pop();
    if (name && regexp.test(name)) return;

    this.element.classList.add('is-invalid');
    this.element.form.commit.setAttribute('disabled', true);
    this.element.insertAdjacentHTML('afterend',
      '<div class="invalid-feedback">File names may not contain spaces or any characters except\
       a–z, 0–9, or dashes. <b>Please rename this file on your computer before uploading it.</b></div>');
  }

  reset() {
    this.element.classList.remove('is-invalid');
    this.element.form.commit.removeAttribute('disabled');
    const warning = this.element.nextElementSibling;
    if (warning.matches('.invalid-feedback')) warning.remove();
  }
}

