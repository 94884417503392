import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('slug me baby')
  }

  format() {
    const regexp = new RegExp('^[-a-z0-9]+$');
    if (regexp.test(this.element.value)) return;

    this.element.value = this.element
                             .value
                             .toString()
                             .toLowerCase()
                             .replace(/\s+/, '-');
  }

  squish() {
    this.element.value = this.element
                             .value
                             .toString()
                             .trim()
                             .replace(/-+$/, '')
                             .replace(/^-+?(?!-)/, '');
  }
}

