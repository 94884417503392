import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['item', 'btn'];

  select(evt) {
    const elm = evt.currentTarget;
    if (this.data.get('exclusive') == '1') {
      this.btnTargets.forEach((elm) => {
        elm.innerText = '☆';
        elm.classList.remove('btn-primary', 'text-white');
      });
    }
    elm.innerText = (elm.innerText == '☆') ? '★' : '☆';
    elm.classList.toggle('btn-primary');
    elm.classList.toggle('text-white');
  }
}

