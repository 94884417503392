import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log('let’s do the splits')
    this.edit_form = this.element;
    this.split_form = document.getElementById('split_form');
  };

  choose(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    var splitter = this.split_form.content.firstElementChild.cloneNode(true);
    splitter.classList.add('split-tool');
    this.edit_form.before(splitter);
  }
}
